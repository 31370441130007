@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* font-family: 'Poppins';
font-style: normal; */
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}

.bg-orange-500 {
  background-color: #ed8936;
}

.text-orange-500 {
  color: #ed8936;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
/* .bg-image {
  background-image: url("./assets/img/landing-hero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: fixed;
} */

.hero-image {
  background-image: linear-gradient(
      rgba(211, 215, 178, 0.092),
      rgba(0, 0, 0, 0.5)
    ),
    url("./assets/img/landing-hero.png");
  /* max-width: 100%; */
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 48px;
  height: 24px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 19px;
  height: 19px;
  position: absolute;
  top: 2.5px;
  left: 5px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #56c080;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.hero-image-2 {
  background-image: linear-gradient(rgba(211, 215, 178, 0.022), #253646),
    url("./assets/img/Mask group.png");
  /* max-width: 100%; */
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

@media only screen and (max-width: 768px) {
  .hero-image {
    background-position: center;
    height: 15%;
  }

  .overlay-bg {
    background: rgba(171, 181, 176, 0.237);
  }
}

.overlay-bg-ordinary-people {
  background: rgba(171, 181, 176, 0.237);
}

#slideset1 {
  height: 85vh;
  position: relative;
}

@media only screen and (max-width: 320px) {
  #slideset1 {
    height: 75vh;
  }
}

#slideset1 > * {
  animation: 12s autoplay infinite linear;
  position: absolute;
  top: 0;
  left: 50;
  opacity: 0;
}

@keyframes autoplay {
  0% {
    opacity: 0;
  }
  4% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  37.33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#slideset1 > *:nth-child(1) {
  animation-delay: 0s;
}
#slideset1 > *:nth-child(2) {
  animation-delay: 4s;
}
#slideset1 > *:nth-child(3) {
  animation-delay: 8s;
}
#slideset1 > *:nth-child(4) {
  animation-delay: 12s;
}
#slideset1 > *:nth-child(5) {
  animation-delay: 16s;
}

.bg-image-landing {
  background-image: url("./assets/img/landing-hero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  /* position: fixed; */
}
.landing-last {
  background-image: linear-gradient(
      180deg,
      rgba(37, 54, 70, 0) 25.24%,
      rgba(37, 54, 70, 0.85) 87.68%
    ),
    url("./assets/img/Mask\ group.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;

  /* position: fixed; */
}
.landing-bg3 {
  background-image: url("./assets/img/pexels-greta-hoffman-9705778\ 1-2.png");
  background-repeat: no-repeat;
  background-position: center;

  height: 100%;
  width: 100%;
}
.linear-bg {
  background: linear-gradient(
    180deg,
    rgba(37, 54, 70, 0) 25.24%,
    rgba(37, 54, 70, 0.85) 87.68%
  );
}
.form-bg {
  background: rgba(171, 181, 176, 0.237);
}

.bg-grad {
  background: linear-gradient(93.92deg, #1ce783 3.14%, #31fe99 100%);
}

.seedja-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 15px;
  background: #cfecde;
  cursor: pointer;
  border-radius: 35%;
  margin: 2px 1px;
}
.svg-bg {
  background-image: url("./assets/img/Vector\ 2.svg");
  background-repeat: no-repeat;
}
.svg-bg2 {
  background-image: url("./assets/img/about-img/Vector2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

input::placeholder {
  color: #ffffff;
}

textarea::placeholder {
  color: #ffffff;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ffffff;
}

input.box::-webkit-input-placeholder,
textarea.box::-webkit-input-placeholder {
  color: black;
}

input.quiz::-webkit-input-placeholder,
textarea.quiz::-webkit-input-placeholder {
  color: #fbfa58;
}
input.quiz,
textarea.quiz {
  color: #fbfa58;
}

input.loan::-webkit-input-placeholder,
textarea.loan::-webkit-input-placeholder {
  color: 'black';
}
input.loan,
textarea.loan {
  color: 'black';
}

/* 
input.quiz:focus {
  outline:'none';
  border: 'none';
} */

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.loader {
  border-top-color: rgb(44, 239, 174);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
